import React, { useState, useEffect, useRef } from 'react';
import { Container, TextField, Button, Typography, Box, Paper, CircularProgress } from '@mui/material';
import hljs from 'highlight.js';
import 'highlight.js/styles/github-dark.css'; // or another theme

function App() {
  const [question, setQuestion] = useState('');
  const [response, setResponse] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Create a ref for the text field
  const textFieldRef = useRef(null);

  const handleSubmit = () => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_LLM_URL}/ask`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        prompt: question,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setResponse(data.response);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  const handleClear = () => {
    setQuestion('');
    setTimeout(() => {
      if (textFieldRef.current) {
        textFieldRef.current.focus();
      }
    }, 100);
  };

  const handleReset = () => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_LLM_URL}/reset_session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then(() => {
        setResponse('Session reset successfully.');
        setError(null);
        setLoading(false);
        handleClear();
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    const checkServerConnection = () => {
      fetch(`${process.env.REACT_APP_LLM_URL}/checkLLM`, {
        method: 'POST', // Change method to POST
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "LLM is live") {
            setError(null); // Server is live
          } else {
            setError('Server is shut, ask Prahlad to make it Live');
          }
        })
        .catch(() => {
          setError('Server is shut, ask Prahlad to make it Live');
        });
    };
  
    checkServerConnection();
  }, []);

  useEffect(() => {
    hljs.highlightAll();
  }, [response]);

  const renderResponse = (text) => {
    const parts = text.split(/(```[\s\S]*?```)/g);

    return parts.map((part, index) => {
      if (part.startsWith('```') && part.endsWith('```')) {
        return (
          <Box
            key={index}
            sx={{
              margin: '10px 0',
              padding: '10px',
              backgroundColor: '#2e2e2e',
              borderRadius: '4px',
              overflowX: 'auto',
              scrollBehavior: 'smooth',
              WebkitOverflowScrolling: 'touch',
            }}
          >
            <pre style={{ margin: 0, color: '#f5f5f5' }}>
              <code className="language-javascript">{part.slice(3, -3)}</code>
            </pre>
          </Box>
        );
      } else {
        return (
          <Typography key={index} variant="body1" component="span">
            {part}
          </Typography>
        );
      }
    });
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: '50px' }}>
      <Box
        sx={{
          position: 'relative',
          padding: '20px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          backgroundColor: '#fff',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
          }}
        >
          <Typography variant="caption" component="p" color="textSecondary">
            Developed by Prahlad
          </Typography>
        </Box>
        <Typography variant="h4" component="h1" gutterBottom>
          Ask a Question
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            label="Type your question here"
            variant="outlined"
            fullWidth
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            onKeyDown={handleKeyDown}
            margin="normal"
            inputRef={textFieldRef}
            disabled={error !== null} // Disable input if the server is not live
          />
          <Button
            variant="contained"
            color="error"
            onClick={handleClear}
            sx={{ marginLeft: '10px', minWidth: '120px' }}
            disabled={error !== null} // Disable button if the server is not live
          >
            Clear
          </Button>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            fullWidth
            sx={{ marginRight: '10px', minWidth: '120px' }}
            disabled={error !== null} // Disable button if the server is not live
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleReset}
            fullWidth
            sx={{ minWidth: '120px' }}
            disabled={error !== null} // Disable button if the server is not live
          >
            Reset
          </Button>
        </Box>
        <Box sx={{ marginTop: '20px' }}>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100px',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {response && (
                <Paper elevation={3} sx={{ padding: '15px', backgroundColor: '#f5f5f5' }}>
                  {renderResponse(response)}
                </Paper>
              )}
              {error && (
                <Typography variant="body1" color="error">
                  {error}
                </Typography>
              )}
            </>
          )}
        </Box>
      </Box>
    </Container>
  );
}

export default App;